export function getGeoapifyAutocompleteUrl(search: string, currentLocale: string, withAddress: boolean = false): string {
  let url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${search}&filter=countrycode:za,al,dz,de,ad,sa,ar,am,au,at,be,ba,bw,br,bg,ca,cl,co,kr,cr,hr,ci,dk,es,ee,us,fi,fr,ge,gi,gr,hu,id,iq,ir,ie,is,il,it,je,kz,lv,li,lt,lu,mk,mt,ma,mr,mx,mc,me,ni,ng,no,nz,py,nl,pe,ph,pl,pt,mc,cz,ro,gb,gb,ru,sm,sv,sn,rs,sk,si,se,ch,tw,tg,tn,tr,ua,uy&lang=${currentLocale}&format=json&apiKey=${process.env.geoapifyKey}`;
  if (!withAddress) {
    url += '&type=city';
  }

  return url;
}

export function getGeoapifyGeocodeUrl(lat: number, lon: number, currentLocale: string): string {
  return `https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${lon}&lang=${currentLocale}&apiKey=${process.env.geoapifyKey}`;
}
