
import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class BaseAlert extends Vue {
  private alertOpen: boolean = true;

  closeAlert() {
    this.alertOpen = false;
    this.$emit('close');
  }
}
