import { Component, Vue } from 'nuxt-property-decorator';
import Api from '../services/api/Api';
import { Entrypoints } from '../config/entrypoints';
import { Config, ConfigClass } from '../interfaces/types';

@Component({
  components: {},
})
export class ConfigMixin extends Vue {
  configApi: Api = new Api(Entrypoints.Config);
  configs = new ConfigClass();

  async loadConfig() {
    this.configApi = new Api(Entrypoints.Config);

    const configRes = await this.configApi.findAll({ deletedAt: false });
    const configItems: Array<Config> = configRes['hydra:member'];

    configItems.forEach((config: Config) => {
      this.configs[config.name] = config.value;
    });

    if (this.configs.notation_rate) {
      this.configs.notation_rate = this.configs.notation_rate.replace(',', '.');
    }
  }
}
