
import { Component, Vue } from 'nuxt-property-decorator';
import BaseButton from '~/components/base/BaseButton.vue';

@Component({
  components: { BaseButton },
})
export default class LandingSectionTwo extends Vue {
  private items = [
    {
      image: '/3.webp',
      name: this.$t('home_landing.users'),
      stat: this.$t('home_landing.users.stat'),
    },
    {
      image: '/4.webp',
      name: this.$t('home_landing.addresses'),
      stat: this.$t('home_landing.addresses.stat'),
    },
    {
      image: '/5.webp',
      name: this.$t('home_landing.pictures'),
      stat: this.$t('home_landing.pictures.stat'),
    },
    {
      image: '/6.webp',
      name: this.$t('home_landing.reviews'),
      stat: this.$t('home_landing.reviews.stat'),
    },
    {
      image: '/7.webp',
      name: this.$t('home_landing.comments'),
      stat: this.$t('home_landing.comments.stat'),
    },
    {
      image: '/8.webp',
      name: this.$t('home_landing.languages'),
      stat: this.$t('home_landing.languages.stat'),
    },
  ];
}
