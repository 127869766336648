
import { Component, Prop } from 'nuxt-property-decorator';
import { TwMergeMixin } from '~/ts/mixins/tw_merge';

@Component
export default class BasePreviewImage extends TwMergeMixin {
  @Prop({ required: true }) image!: string;
  @Prop({ default: '', required: false }) imageAlt!: string;
  @Prop({ required: false }) textOver!: string;
  @Prop({ required: false }) textUnder!: string | undefined;

  get defaultClass() {
    return 'relative pb-10 mr-2.5';
  }
}
