
import { Component, Vue } from 'nuxt-property-decorator';
import BaseButton from '~/components/base/BaseButton.vue';

@Component({
  components: { BaseButton },
})
export default class LandingSection extends Vue {
  onAppClick() {
    this.$emit('on-mobile-apps-modal');
  }
}
