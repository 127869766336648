
import { Component, mixins } from 'nuxt-property-decorator';
import { NuxtOptionsHead } from '@nuxt/types/config/head';
import LandingSectionTwo from '~/components/LandingSectionTwo.vue';
import LangSwitcher from '~/components/LangSwitcher.vue';
import LandingSection from '~/components/LandingSection.vue';
import StoresLinks from '~/components/application/StoresLinks.vue';
import BasePreviewImage from '~/components/base/BasePreviewImage.vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import { Destination, GeoapifyAutocompleteResponse, Step, TripClass } from '~/ts/interfaces/types';
import { TripApi } from '~/ts/services/api/TripApi';
import { MediaMixin } from '~/ts/mixins/media';
import SluggerService from '~/ts/services/SluggerService';
import BaseAlert from '~/components/base/BaseAlert.vue';
import BaseButton from '~/components/base/BaseButton.vue';
import BaseCarousel from '~/components/base/BaseCarousel.vue';
import Modal from '~/components/Modal.vue';
import NotationLikes from '~/components/tools/NotationLikes.vue';
import { TextMixin } from '~/ts/mixins/text';
import Api from '~/ts/services/api/Api';
import { Entrypoints } from '~/ts/config/entrypoints';
import { ConfigMixin } from '~/ts/mixins/config';
import CustomAutocomplete from '~/components/CustomAutocomplete.vue';

@Component({
  auth: false,
  components: {
    CustomAutocomplete,
    BaseAlert,
    BaseButton,
    BaseCarousel,
    BaseIcon,
    BasePreviewImage,
    LandingSection,
    LandingSectionTwo,
    LangSwitcher,
    Modal,
    NotationLikes,
    StoresLinks,
  },
})
export default class IndexPage extends mixins(MediaMixin, TextMixin, ConfigMixin) {
  private mapSearch: string = '';
  private appsModalOpen = false;

  private tripApi: TripApi = new TripApi();
  private trips: Array<TripClass> = [];

  private destinationApi: Api = new Api(Entrypoints.Destination);
  private destinations: Array<Destination> = [];

  private stepApi: Api = new Api(Entrypoints.Step);
  private steps: Array<Step> = [];

  head(): NuxtOptionsHead {
    return {
      meta: [{ content: 'index', hid: 'robots', name: 'robots' }],
    };
  }

  onPlaceChange(addressData: GeoapifyAutocompleteResponse) {
    const lat = addressData.lat;
    const lng = addressData.lon;

    this.$router.push(this.localePath({ name: 'map', query: { lat: String(lat), lng: String(lng), zoom: '13' } }));
  }

  async fetch() {
    await Promise.all([this.loadDestinations(), this.loadTrips(), this.loadSteps(), this.loadConfig()]);
  }

  loadTrips() {
    this.tripApi = new TripApi();

    return this.tripApi.findAll({ topTrip: 1 }).then((tripRes) => {
      this.trips = tripRes['hydra:member'];
    });
  }

  loadDestinations() {
    return this.destinationApi.findAll({ status: 1 }).then((destinationRes) => {
      this.destinations = destinationRes['hydra:member'];
    });
  }

  loadSteps() {
    this.stepApi = new Api(Entrypoints.Step);

    return this.stepApi.findAll({ status: 1 }).then((stepRes) => {
      this.steps = stepRes['hydra:member'];
    });
  }

  getDestinationSrc(destination: Destination): string {
    if (!destination.mapUrl) return '';

    try {
      const url = new URL(destination.mapUrl);
      const lat = url.searchParams.get('lat') || '';
      const lng = url.searchParams.get('lng') || '';
      const zoom = url.searchParams.get('zoom') || '';

      return this.localePath({ name: 'map', query: { lat, lng, zoom } });
    } catch (e) {
      return '';
    }
  }

  getStepSrc(step: Step): string {
    if (!step.url) return '';

    try {
      const url = new URL(step.url);
      const args = url.pathname.split('/');

      const slug = args.pop() || '';
      return this.localePath({ name: 'map-slug', params: { slug } });
    } catch (e) {
      return '';
    }
  }

  private settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          initialSlide: 2,
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: 4,
    slidesToShow: 4,
    speed: 1000,
  };

  slugTrip(trip: TripClass) {
    return SluggerService.slugTrip(trip);
  }
}
