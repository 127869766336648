
import { Component } from 'nuxt-property-decorator';
import { TwMergeMixin } from '~/ts/mixins/tw_merge';
import { RessourceURL } from '~/ts/config/enumerations';

@Component
export default class StoresLinks extends TwMergeMixin {
  public itunes: string = RessourceURL.ITUNES;

  public playStore: string = RessourceURL.PLAY_STORE;

  get defaultClass() {
    return 'grid grid-cols-2 h-10 w-fit justify-center gap-2 items-center';
  }
}
