
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import axios, { CancelTokenSource } from 'axios';
import debounce from 'lodash/debounce';
import { getGeoapifyAutocompleteUrl } from '~/ts/utils/geoapify';

@Component
export default class CustomAutocomplete extends Vue {
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) isBlack!: boolean;
  @Prop({ default: () => {} }) withAddress!: boolean;

  searchQuery: string = this.value;
  suggestions: Array<{ formatted: string }> = [];
  highlightedIndex: number = -1;
  cancelTokenSource: CancelTokenSource | null = null;
  debounceTime = 500;
  debouncedFetchSuggestions = debounce(this.fetchSuggestions, this.debounceTime);

  @Watch('value')
  onValueChanged(newValue: string) {
    this.searchQuery = newValue;
  }

  @Watch('searchQuery')
  onSearchQueryChanged(newQuery: string) {
    this.$emit('input', newQuery);
  }

  onInput() {
    this.cancelPreviousRequest();
    this.debouncedFetchSuggestions();
  }

  cancelPreviousRequest() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Operation canceled due to new request.');
    }
    this.cancelTokenSource = axios.CancelToken.source();
  }

  async fetchSuggestions() {
    if (this.searchQuery.length < 3) {
      this.suggestions = [];
      return;
    }

    const url = getGeoapifyAutocompleteUrl(this.searchQuery, this.$i18n.locale, this.withAddress)
    try {
      const response = await axios.get(url, {
        cancelToken: this.cancelTokenSource?.token,
      });

      this.suggestions = response.data.results.map((feature: any) => ({
        formatted: feature.formatted,
        ...feature,
      }));
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Erreur lors de la récupération des suggestions:', error);
      }
    }
  }

  highlightNext() {
    if (this.highlightedIndex < this.suggestions.length - 1) {
      this.highlightedIndex++;
    }
  }

  highlightPrevious() {
    if (this.highlightedIndex > 0) {
      this.highlightedIndex--;
    }
  }

  selectSuggestion(index: number = this.highlightedIndex) {
    if (index >= 0 && index < this.suggestions.length) {
      const selectedSuggestion = this.suggestions[index];
      this.searchQuery = selectedSuggestion.formatted;
      this.$emit('place_changed', selectedSuggestion);
      this.suggestions = [];
      this.highlightedIndex = -1;
    }
  }

  onClickOutside (event: any) {
    this.suggestions = [];
  }
}
