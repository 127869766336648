
import { Component, Prop, Watch } from 'nuxt-property-decorator';
import { twMerge } from 'tailwind-merge';
import BaseButton from '~/components/base/BaseButton.vue';
import { TwMergeMixin } from '~/ts/mixins/tw_merge';

@Component({
  components: { BaseButton },
})
export default class Modal extends TwMergeMixin {
  @Prop({ default: true, required: false }) private submitButton!: boolean;
  @Prop({ default: false, required: false }) private cancelButton!: boolean;
  @Prop({ default: true }) closeIcon!: boolean;
  @Prop({ default: false }) detach!: boolean;
  @Prop({ default: null }) containerClass!: string | null;
  @Prop({ default: null }) backgroundClass!: string | null;
  @Prop({ default: false }) blur!: boolean;
  @Prop({ default: true, required: false }) borderTitle!: boolean;
  @Prop({ default: false, required: false }) small!: boolean;

  private created = false;

  get mergedContainerClass() {
    return twMerge('relative max-h-[70svh] overflow-y-auto overflow-x-hidden pt-2', this.containerClass);
  }

  get mergedBackgroundClass() {
    return twMerge('fixed inset-0 h-full w-full bg-black/40', this.backgroundClass);
  }

  get defaultClass() {
    return [
      'relative max-h-[90svh] w-full  rounded-md bg-white px-6 py-5 md:h-fit md:shadow-lg',
      this.small ? 'max-w-sm md:max-w-md' : 'max-w-[40rem] md:max-w-[50rem]',
    ];
  }

  mounted() {
    this.createModal();
  }

  updated() {
    this.createModal();
  }

  onClose() {
    this.$emit('close');
    this.destroyModal();
  }

  beforeDestroy() {
    this.destroyModal();
  }

  beforeRouteLeave(_to: any, _from: any, next: any) {
    this.destroyModal();
    next();
  }

  createModal() {
    if (!this.detach) return;

    if (!this.created && this.$refs.modal instanceof HTMLElement) {
      document.body.prepend(this.$refs.modal);
      this.$refs.modal.hidden = false;
      this.created = true;
    }
  }

  destroyModal() {
    if (!this.detach) return;

    if (this.$refs.modal instanceof HTMLElement) {
      this.created = false;

      if (this.$refs.modalWrapper instanceof HTMLElement) {
        this.$refs.modalWrapper.prepend(this.$refs.modal);
        this.$refs.modal.hidden = true;
        return;
      }

      this.$refs.modal.remove();
    }
  }

  onCloseKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Enter') return this.onClose();
  }

  onSubmit() {
    this.$emit('submit');
  }

  @Watch('$route', { deep: true })
  onRouteChange() {
    this.destroyModal();
  }
}
