
import { Component, Prop } from 'nuxt-property-decorator';
import { TwMergeMixin } from '~/ts/mixins/tw_merge';
import BaseIcon from '~/components/base/BaseIcon.vue';
import { IconType } from '~/ts/interfaces/types';

@Component({
  components: { BaseIcon },
})
export default class NotationLikes extends TwMergeMixin {
  @Prop({ required: true }) notation!: number;
  @Prop({ default: false }) editable!: boolean;
  @Prop({ default: true }) emptyHeart!: boolean;
  @Prop({ default: 5 }) notationMax!: number;
  @Prop({ default: 'Heart' }) fillIcon!: IconType;
  @Prop({ default: '#E3444A' }) fillIconColor!: string;
  @Prop({ default: 'EmptyHeart' }) emptyIcon!: IconType;
  @Prop({ default: '#000' }) emptyIconColor!: string;
  @Prop({ default: 'h-5 w-5' }) size!: string;
  @Prop({ default: false }) handleFloat!: boolean;

  get defaultClass() {
    return 'flex h-max justify-center gap-1';
  }

  private temporaryNotation = 0;
  private isIn: boolean = false;

  onHoverIcon(tempNote: number): void {
    if (!this.editable) return;

    this.temporaryNotation = tempNote;
    this.isIn = true;
  }

  onMouseOutIcon(): void {
    if (!this.editable) return;

    this.temporaryNotation = 0;
    this.isIn = false;
  }

  canFillIcon(index: number) {
    if (this.isIn && this.temporaryNotation) return this.temporaryNotation >= index;
    if (!this.handleFloat) return this.notation >= index;

    return this.getFillPercent(index);
  }

  canEmptyIcon(index: number) {
    if (!this.emptyHeart) return false;
    if (!this.handleFloat) return !this.canFillIcon(index);

    return this.getEmptyPercent(index);
  }

  changeNotation(note: number): void {
    if (!this.editable) {
      return;
    }
    this.$emit('notation-change', note);
  }

  getFillPercent(index: number) {
    return Math.min(100, this.notation * 100 - 100 * (index - 1));
  }

  getEmptyPercent(index: number) {
    return Math.max(0, 100 * index - this.notation * 100);
  }
}
